import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";

// Buildings
const UserManagement = Loader(lazy(() => import('src/content/admin/UserManagement')));

const adminRoutes = [
  {
    path: '/',
    element: <Navigate to='users' replace />,
  },
  {
    path: 'users',
    element: <UserManagement />,
  },
];

export default adminRoutes;
