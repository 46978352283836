import { Box } from "@mui/material";

import Logo from "src/components/LogoSign";

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
      }}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <div style={{ animation: 'spin 1s linear infinite' }}>
        <Logo />
      </div>
      <style>{`
            @keyframes spin {
                 0% { transform: rotate(360deg); }
                 100% { transform: rotate(0deg); }
            }
        `}</style>
    </Box>
  );
}

export default AppInit;
