import { lazy } from "react";
import Loader from "../components/Loader";

const Customers = Loader(lazy(() => import('src/content/dealer/Customers')));

const customersRoutes = [
  {
    path: '/',
    element: <Customers />,
  },
];

export default customersRoutes;
