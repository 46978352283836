import { useContext } from "react";
import Scrollbar from "src/components/Scrollbar";
import { SidebarContext } from "src/contexts/SidebarContext";
import { alpha, Box, darken, Divider, Drawer, lighten, styled, Typography, useTheme } from "@mui/material";
import SidebarTopSection from "./SidebarTopSection";
import SidebarMenu from "./SidebarMenu";
import SidebarFooter from "./SidebarFooter";
import Logo from "src/components/LogoSign";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 61px;
`,
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                display: 'flex',
              }}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <Logo />
                <Typography variant='h3' sx={{ display: 'flex', alignItems: 'baseline', marginLeft: '10px' }}>
                  RoomHub&nbsp;
                  {process.env.REACT_APP_MODE == 'prod' &&
                    <Typography variant='h5'>IVH</Typography>
                  }
                  {process.env.REACT_APP_MODE == 'test' &&
                    <Typography variant='h5' color={"primary"}>TEST</Typography>
                  }
                  {process.env.REACT_APP_MODE == 'dev' &&
                    <Typography variant='h5' color={"primary"}>DEV</Typography>
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              my: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarTopSection />
          <Divider
            sx={{
              my: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <SidebarFooter />
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant='temporary'
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5),
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  display: 'flex',
                }}
              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Logo />
                  <Typography variant='h3' sx={{ display: 'flex', alignItems: 'baseline', marginLeft: '10px' }}>
                    RoomHub&nbsp;
                    {process.env.REACT_APP_MODE == 'prod' &&
                      <Typography variant='h5'>IVH</Typography>
                    }
                    {process.env.REACT_APP_MODE == 'test' &&
                      <Typography variant='h5' color={"primary"}>TEST</Typography>
                    }
                    {process.env.REACT_APP_MODE == 'dev' &&
                      <Typography variant='h5' color={"primary"}>DEV</Typography>
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                my: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarTopSection />
            <Divider
              sx={{
                my: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <SidebarFooter />
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
