const itJSON = {
  'none': 'Non inclusa',
  'english': 'Inglese',
  'italian': 'Italiana',
  'international': 'Internazionale',
  'continental': 'Continentale',
  'american': 'Americana',
  'full_board': 'Pensione completa',
  'half_board': 'Mezza pensione',
  'room_only': 'Room only',
  'bed_and_breakfast': 'Bed & Breakfast',
  'all_inclusive': 'All inclusive',
  'all_inclusive_premium': 'All inclusive premium',
};

export default itJSON;

