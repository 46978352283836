import axios, { AxiosInstance } from "axios";
import Auth from "../helper/Auth";

export const TOKEN_LIFETIME = 60000 /*1m*/ * 55;

const apiUrl = process.env.REACT_APP_SERVER_API_ENDPOINT;

export type ApiService = 'server'|'api'|'auth'|'hooks';

export const getLatestAccessToken = async () => {
	if (Date.now() - (new Date(+localStorage.getItem('accessTokenDate'))).getTime() > TOKEN_LIFETIME) {
		await Auth.login(localStorage.getItem('email'), localStorage.getItem('password'));
	}
	return localStorage.getItem('accessToken');
}

const client = async (formData: boolean = false, service: ApiService = "server", authenticate = true, version = 1): Promise<AxiosInstance> => {
	let accessToken;
	if (authenticate)
		accessToken = await getLatestAccessToken();

	return axios.create({
		baseURL: `${apiUrl}/${service}/v${version}/`,
		timeout: 31000,
		headers: {
			Accept:             'application/json',
			Authorization:      accessToken ? `Bearer ${accessToken}` : undefined,
			...(formData ? {
				"Content-Type": "multipart/form-data"
			} : {})
			// 'X-Requested-With': 'XMLHttpRequest',
		},
	});
};

export default client;

