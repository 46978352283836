import { lazy } from "react";
import Loader from "../components/Loader";

const Reports = Loader(lazy(() => import('src/content/supplier/Reports')));

const buildingsRoutes = [
  {
    path: '/',
    element: <Reports />,
  },
  {
    path: ':buildingId',
    element: <Reports />,
  },
];

export default buildingsRoutes;
