import { UserDto } from "./api.dto";

export type UserRole = 'supplier' | 'wholesaler' | 'dealer' | 'admin';

export default class UserModel implements UserDto {
	id?: string;

	email: string;

	name: string;

	role: UserRole;

	bans: string[];

	constructor(user: UserDto) {
		this.id = user.id;
		this.email = user.email;
		this.name = user.name;
		this.role = user.role;
		this.bans = user.bans;
	}

	hasRight = (right: string): boolean => {
		return !this.bans.some(userRight => this.isRightBanned(userRight, right));
	};

	hasSomeRights = (rights: string[]): boolean[] => {
		const allowedList = rights?.map(this.hasRight);
		allowedList.unshift(allowedList.some(allowed => allowed));
		return allowedList;
	};

	hasRole = (role: string): boolean => {
		return this.role == role;
	};

	hasRoles = (roles: string[]): boolean[] => {
		const allowedList = roles?.map(requiredRole => this.role == requiredRole);
		allowedList.unshift(allowedList.some(allowed => allowed));
		return allowedList;
	};

	private isRightBanned = (userBan: string, requiredRight: string): boolean => {

		if (!userBan || !requiredRight)
			return false;

		// console.log('userAuth', userRight);
		if (!userBan.includes('*') && !requiredRight.includes('|'))
			return userBan == requiredRight;

		const requiredList = requiredRight.split('.').filter(s => s.length > 0);
		const userBanList = userBan.split('.').filter(s => s.length > 0);

		// console.log('requiredAuth', requiredRight);
		let doubleStarIndexReduction = 0;
		return requiredList.every((req, index) => {

			const orCompare = req.split('|');

			const i = index - doubleStarIndexReduction;

			if (userBanList[i] == '*' || (orCompare.length > 0
				? orCompare.some(comp => comp == userBanList[i])
				: req == userBanList[i])) {
				if (index + 1 >= requiredList.length) {
					// console.log('C');
					return userBanList.length == i + 1;
				}
				// console.log('D');
				return true;
			}
			if (userBanList[i] == '**') {
				const nextItem = userBanList[i + 1];
				if (!nextItem) {
					doubleStarIndexReduction++;
					// console.log('E');
					return true;
				} else {
					if (orCompare.length > 0
						? orCompare.some(comp => comp == nextItem)
						: req == nextItem) {
						doubleStarIndexReduction--;
						if (index + 1 >= requiredList.length) {
							// console.log('F');
							return userBanList.length == (index - doubleStarIndexReduction) + 1;
						}
						// console.log('G');
						return true;
					} else {
						if (index + 1 >= requiredList.length) {
							// console.log('L');
							return false;
						}
						doubleStarIndexReduction++;
						// console.log('H');
						return true;
					}
				}
			}
			// console.log('I');
			return false;
		});
	};
}