import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";

// Buildings
const Deals = Loader(lazy(() => import('src/content/wholesaler/Deals')));
const DealsCreate = Loader(lazy(() => import('src/content/wholesaler/Deals/create')));

const dealsRoutes = [
  {
    path: '/',
    element: <Navigate to='list' replace />,
  },
  {
    path: 'list',
    element: <Deals />,
  },
  {
    path: 'list/:buildingId',
    element: <Deals />,
  },
  {
    path: 'create',
    element: <DealsCreate />,
  },
  {
    path: 'create/:buildingId',
    element: <DealsCreate />,
  },
  {
    path: 'edit/:dealId',
    element: <DealsCreate />,
  },
];

export default dealsRoutes;
