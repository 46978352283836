import { PartialRouteObject } from "react-router";

import Authenticated from "src/components/Authenticated";
import BaseLayout from "src/layouts/BaseLayout";
import ExtendedSidebarLayout from "src/layouts/ExtendedSidebarLayout";
import baseRoutes from "./base";
import buildingsRoutes from "./buildings";
import dealsRoutes from "./deals";
import reportsRoutes from "./reports";
import promotionsRoutes from "./promotions";
import supplementsRoutes from "./supplements";
import customizeBuildingRoutes from "./customize-building";
import bookingsRoutes from "./bookings";
import dealersRoutes from "./dealers";
import customersRoutes from "./customers";
import wholesalersRoutes from "./wholesalers";
import roomTypesRoutes from "./room-types";
import adminRoutes from "./admin";
import Loader from "../components/Loader";
import { lazy } from "react";

const ApiDocs = Loader(lazy(() => import('src/content/docs/ApiDocs')));

const Default = Loader(lazy(() => import('src/content/Default')));
const Dashboard = Loader(lazy(() => import('src/content/analytics/dashboard')));
const Maintenance = Loader(lazy(() => import('src/content/common/Maintenance')));
const DiscountCodes = Loader(lazy(() => import('src/content/dealer/DiscountCodes')));
const Featured = Loader(lazy(() => import('src/content/dealer/Featured')));
const Searches = Loader(lazy(() => import('src/content/analytics/searches')));

const router: PartialRouteObject[] = [
	{
		path: '*',
		element: <BaseLayout />,
		children: baseRoutes,
	},
	{
		path: 'api/v1',
		element: 	<ApiDocs />,
	},
	{
		path: '/',
		element: (
			<Authenticated>
				<ExtendedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '/',
				element: <Default />,
			},
			{
				path: 'discount-code',
				element: <DiscountCodes />,
			},
			{
				path: 'featured',
				element: <Featured />,
			},
			{
				path: 'dashboard',
				element: <Dashboard />,
			},
			{
				path: 'searches',
				element: <Searches />,
			},
			{
				path: 'buildings',
				children: buildingsRoutes,
			},
			{
				path: 'reports',
				children: reportsRoutes,
			},
			{
				path: 'promotions',
				children: promotionsRoutes,
			},
			{
				path: 'supplements',
				children: supplementsRoutes,
			},
			{
				path: 'room-types',
				children: roomTypesRoutes,
			},
			{
				path: 'deals',
				children: dealsRoutes,
			},
			{
				path: 'dealers',
				children: dealersRoutes,
			},
			{
				path: 'customize-building',
				children: customizeBuildingRoutes,
			},
			{
				path: 'bookings',
				children: bookingsRoutes,
			},
			{
				path: 'customers',
				children: customersRoutes,
			},
			{
				path: 'wholesalers',
				children: wholesalersRoutes,
			},
			{
				path: 'admin',
				children: adminRoutes,
			},
		],
	},
];

export default router;
