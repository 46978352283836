import { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

import { alpha, Avatar, Box, styled, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { firstCharToUpper } from "../../../../helper/string";
import EnumToReadable from "../../../../models/enum-to-readable";

const MenuUserBox = styled(Box)(
	({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
	({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
	({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`,
);

const UserBoxDescription = styled(Typography)(
	({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor, 0.6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`,
);

function SidebarTopSection() {
	const { t }: { t: any } = useTranslation();
	const theme = useTheme();

	const navigate = useNavigate();
	const location = useLocation();
	const { user, logout } = useAuth();

	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleLogout = async (): Promise<void> => {
		try {
			handleClose();
			await logout();
			navigate('/');
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Box
			sx={{
				textAlign: 'center',
				mx:        2,
				pt:        1,
				position:  'relative',
			}}
		>
			<Avatar
				sx={{
					width:  68,
					height: 68,
					mb:     2,
					mx:     'auto',
				}}
				alt={user.name}
				src={
					// @ts-ignore
					user.avatar}
			/>

			<Typography
				variant='h4'
				sx={{
					color: `${theme.colors.alpha.trueWhite[100]}`,
				}}
			>
				{user.name}
			</Typography>
			<Typography
				variant='subtitle1'
				sx={{
					color: `${theme.colors.alpha.trueWhite[70]}`,
				}}
			>
				{firstCharToUpper(EnumToReadable.roleType[user.role])}
			</Typography>
		</Box>
	);
}

export default SidebarTopSection;
