import { lazy } from "react";
import Loader from "../components/Loader";

const Supplements = Loader(lazy(() => import('src/content/supplier/Supplements')));

const buildingsRoutes = [
  {
    path: '/',
    element: <Supplements />,
  },
  {
    path: ':buildingId',
    element: <Supplements />,
  },
];

export default buildingsRoutes;
