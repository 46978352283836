import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";

// Buildings
const Buildings = Loader(lazy(() => import('src/content/supplier/Buildings')));
const BuildingsEdit = Loader(lazy(() => import('src/content/supplier/Buildings/create')));

const buildingsRoutes = [
  {
    path: '/',
    element: <Navigate to='list' replace />,
  },
  {
    path: 'list',
    element: <Buildings />,
  },
  {
    path: 'edit',
    children: [
      {
        path: '/',
        element: <Navigate to='../create' replace />,
      },
      {
        path: ':buildingId',
        element: <BuildingsEdit />,
      },
    ],
  },
];

export default buildingsRoutes;
