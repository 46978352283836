import { lazy } from "react";
import Loader from "../components/Loader";

const Customize = Loader(lazy(() => import('src/content/dealer/Customize')));

const buildingsRoutes = [
  {
    path: '/',
    element: <Customize />,
  },
  {
    path: ':buildingId',
    element: <Customize />,
  },
];

export default buildingsRoutes;
