import i18n from "i18next";

import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import enJSON from "./translations/en";
import itJSON from "./translations/it";

const resources = {
  it: { translation: itJSON },
  en: { translation: enJSON },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: 'it',
    fallbackLng: 'it',
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
