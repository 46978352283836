import { lazy } from "react";
import Loader from "../components/Loader";

const Dealers = Loader(lazy(() => import('src/content/wholesaler/Dealers')));

const dealersRoutes = [
  {
    path: '/',
    element: <Dealers />,
  },
];

export default dealersRoutes;
