import { useContext } from "react";

import {
	alpha,
	Box,
	Button,
	Divider,
	IconButton,
	lighten,
	Stack,
	styled,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { SidebarContext } from "src/contexts/SidebarContext";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";

import HeaderButtons from "./Buttons";
import HeaderUserbox from "./Userbox";
import { t } from "i18next";
import useAuth from "../../../hooks/useAuth";
import { CloudTwoTone } from "@mui/icons-material";

const HeaderWrapper = styled(Box)(
	({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`,
);

function Header() {
	const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
	const theme = useTheme();
	const { user } = useAuth();

	return (
		<HeaderWrapper
			display='flex'
			alignItems='center'
			sx={{
				boxShadow:
					theme.palette.mode === 'dark'
						? '0 1px 0 ' +
						alpha(lighten(theme.colors.primary.main, 0.7), 0.15) +
						', 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)'
						: '0px 2px 8px -3px ' +
						alpha(theme.colors.alpha.black[100], 0.2) +
						', 0px 5px 22px -4px ' +
						alpha(theme.colors.alpha.black[100], 0.1),
			}}
		>
			<Stack
				direction='row'
				divider={<Divider orientation='vertical' flexItem />}
				alignItems='center'
				spacing={2}
			>
				{/*<HeaderSearch />*/}
				{/*<HeaderMenu />*/}
				{user.hasRole('dealer') ?
					<Button
						href="/api/v1"
						endIcon={<CloudTwoTone />}
					>
						{t("Documentazione API")}
					</Button>
					:
					<Button
					href="https://ivh.travel/manager/agreements/agreements_listV2.php"
					target="_blank"
					>
				{t("Vai su Netstorming")}
					</Button>
				}
			</Stack>
			<Box display='flex' alignItems='center'>
				{process.env.REACT_APP_MODE == 'dev' &&
					<Typography variant={"overline"} color={"primary"} mr={1.5}><small><small>v</small></small>{process.env.REACT_APP_VERSION} DEV</Typography>
				}
				{process.env.REACT_APP_MODE == 'test' &&
					<Typography variant={"overline"} color={"primary"} mr={1.5}><small><small>v</small></small>{process.env.REACT_APP_VERSION} TEST</Typography>
				}
				{process.env.REACT_APP_MODE == 'prod' &&
					<Typography variant={"overline"} color={"primary"} mr={1.5}><small><small>v</small></small>{process.env.REACT_APP_VERSION}</Typography>
				}
				<HeaderButtons />
				<HeaderUserbox />
				<Box
					component='span'
					sx={{
						ml:      2,
						display: { lg: 'none', xs: 'inline-block' },
					}}
				>
					<Tooltip arrow title='Toggle Menu'>
						<IconButton color='primary' onClick={toggleSidebar}>
							{!sidebarToggle ? (
								<MenuTwoToneIcon fontSize='small' />
							) : (
								<CloseTwoToneIcon fontSize='small' />
							)}
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
		</HeaderWrapper>
	);
}

export default Header;
