const EnumToReadable = {

	cancellationTargetMode: {
		night: 'notte',
		total: 'totale',
	},

	cancellationTargetType: {
		visitor: 'ospite',
		room:    'stanza',
	},

	cancellationPenaltyType: {
		percentage: 'percentuale',
		currency:   'valuta',
		nights:     'notti',
	},

	closureType: {
		entire_stay: 'soggiorno intero',
		checkin:     'Per checkin',
		checkout:    'Per checkout',
	},

	promotionDiscountType: {
		percentage: 'percentuale',
		currency:   'valuta',
		nights:     'notti',
	},

	promotionFreeNightsMode: {
		firsts:         'prime',
		latests:        'ultime',
		less_expensive: 'meno costose',
		more_expensive: 'più costose',
	},

	promotionTargetType: {
		net:          'prezzo netto',
		sale:         'prezzo di vendita',
		net_and_sale: 'entrambi',
	},

	reportTargetType: {
		booking:      'prenotazione',
		availability: 'disponibilità',
		voucher:      'voucher',
	},

	roleType: {
		supplier:   'fornitore',
		wholesaler: 'grossista',
		dealer:     'canale distributivo',
		admin:      'admin',
	},

	supplementAmountType: {
		currency:            'valuta',
		purchase_percentage: 'percentuale di acquisto',
		sale_percentage:     'percentuale di vendita',
	},

	supplementApplicabilityType: {
		visitor: 'ospite',
		adult:   'adulto',
		child:   'bambino',
		room:    'stanza',
		booking: 'prenotazione',
	},

	supplementCategoryType: {
		upgrade:          'miglioramento',
		optional_service: 'servizio opzionale',
		taxes:            'tasse',
	},

	weekDayType: {
		mon: 'lu',
		tue: 'ma',
		wed: 'me',
		thu: 'gi',
		fri: 've',
		sat: 'sa',
		sun: 'do',
	},

};

export default EnumToReadable;