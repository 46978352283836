import { lazy } from "react";
import Loader from "../components/Loader";

const RoomTypes = Loader(lazy(() => import('src/content/supplier/RoomTypes')));

const roomTypesRoutes = [
	{
		path:    '/',
		element: <RoomTypes />,
	},
	{
		path:    ':buildingId',
		element: <RoomTypes />,
	},
];

export default roomTypesRoutes;
