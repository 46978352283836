import { useSnackbar, VariantType } from "notistack";
import { useCallback } from "react";

const useNotifyError = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback((error, variant: VariantType = 'error') => {
    enqueueSnackbar(error?.response?.data?.message ?? error?.response?.data?.error ?? error?.toString(), { variant });
    console.log(error?.response);
  }, [enqueueSnackbar]);
};

export default useNotifyError;
