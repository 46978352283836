import { combineReducers } from "@reduxjs/toolkit";
import calendar from "../slices/calendar";

const rootReducer = combineReducers({
  // roomTypesBoard: roomTypesBoardReducer
  calendar: calendar.reducer,
  // projectsBoard: projectsBoardReducer,
  // mailbox: mailboxReducer
});

export default rootReducer;
