import client from "../api/client";
import { UserDto } from "../models/api.dto";
import UserModel from "../models/user";

export default class Auth {

  static fetchUser = async () => {
    if(!localStorage.getItem('email') || !localStorage.getItem('password'))
      return null;
    const userDtoResponse = await (await client(false, "auth")).get<UserDto>('user');
    return new UserModel(userDtoResponse?.data);
  };

  static login = async (email: string, password: string) => {
    try {
      const res = await (await client(false, "auth", false)).post<{ accessToken: string; user: UserDto }>('login',
        { email, password });

      if (res.data?.accessToken) {
        Auth.setSession(res.data?.accessToken, email, password);
      }
      return new UserModel(res.data?.user);
    } catch (e) {
      Auth.setSession(null, null, null);
      throw e;
    }
  };

  static logout = () => {
    Auth.setSession(null, null, null);
    console.log('logout:', Date.now().toString());
  };

  private static setSession = (accessToken: string, email: string, password: string): void => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenDate', Date.now().toString());
    } else
      localStorage.removeItem('accessToken');

    if (email)
      localStorage.setItem('email', email);
    else
      localStorage.removeItem('email');

    if (password)
      localStorage.setItem('password', password);
    else
      localStorage.removeItem('password');
  };
}