import { lazy } from "react";

import Loader from "../components/Loader";

const Bookings = Loader(lazy(() => import('src/content/dealer/Bookings')));

const bookingsRoutes = [
  {
    path: '/',
    element: <Bookings />,
  },
];

export default bookingsRoutes;
