import type { ReactNode } from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from '@mui/icons-material/Report';
import UserModel from '../../../../models/user';
import { t } from 'i18next';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import EditIcon from '@mui/icons-material/Edit';
import {
  Dashboard,
  FeaturedVideo,
  LocalOffer,
  LocationCity,
  Luggage,
  MeetingRoom,
  People,
  PeopleAlt,
  Search
} from '@mui/icons-material';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

export const getMenuItems = (user: UserModel): MenuItems[] => {
  // const location = useLocation();

  const [
    hasSomeSupplierRights,
    canListBuildings,
    canCreateBuildings,
    canListReports,
    canListSupplements,
    canListPromotions,
    canListRoomLayouts
  ] = user.hasSomeRights([
    'buildings.list',
    'buildings.create',
    'reports.list',
    'supplements.list',
    'promotions.list',
    'room_layouts.list'
  ]);
  const isSupplier = user.hasRole('supplier');

  const [hasSomeWholesalerRights, canListDeals, canCreateDeals] =
    user.hasSomeRights([
      'wholesaler.deals.list.own|any',
      'wholesaler.deals.create.own|any'
    ]);
  const isWholesaler = user.hasRole('wholesaler');

  const [canListUsers] = user.hasSomeRights(['users.list']);
  const isAdmin = user.hasRole('admin');

  const [hasSomeDealerRights, canCustomize, canBookings, canCustomers] =
    user.hasSomeRights([
      'dealer.customize.all.own|any',
      'dealer.bookings.all.own|any',
      'dealer.customers.all.own|any'
    ]);
  const isDealer = user.hasRole('dealer');

  const canGetOwnUser = user.hasRight('management.users.get.own');
  const canUpdateOwnUser = user.hasRight('management.users.update.own');

  return isAdmin
    ? [
        {
          heading: t('Amministrazione'),
          items: [
            {
              name: t('Gestione Utenti'),
              icon: PeopleAlt,
              link: '/admin/users'
            }
          ]
        }
      ]
    : [
        {
          heading: t('Analisi'),
          items: [
            {
              name: t('Dashboard'),
              icon: Dashboard,
              link: '/dashboard'
            },
            {
              name: t('Ricerche'),
              icon: Search,
              link: '/searches'
            }
          ]
        },
        /*...hasSomeSupplierRights ? [*/ {
          heading: t('Dati Strutture'),
          items: [
            ...(canListBuildings
              ? [
                  {
                    name: t('Strutture'),
                    icon: ApartmentIcon,
                    link: '/buildings/list',
                    badge: location.pathname.startsWith('/buildings/edit')
                      ? 'VIEW'
                      : undefined
                  }
                ]
              : []),
            ...(canListSupplements && (isWholesaler || isSupplier)
              ? [
                  {
                    name: t('Tipi Stanze'),
                    icon: MeetingRoom,
                    link: '/room-types'
                  }
                ]
              : []),
            ...(canListReports && (isWholesaler || isSupplier)
              ? [
                  {
                    name: t('Segnalazioni'),
                    icon: ReportIcon,
                    link: '/reports'
                  }
                ]
              : []),
            ...(canListPromotions && (isWholesaler || isSupplier)
              ? [
                  {
                    name: t('Promozioni'),
                    icon: PriceChangeIcon,
                    link: '/promotions'
                  }
                ]
              : []),
            ...(canListSupplements && (isWholesaler || isSupplier)
              ? [
                  {
                    name: t('Supplementi'),
                    icon: AddShoppingCartIcon,
                    link: '/supplements'
                  }
                ]
              : [])
          ]
        } /*] : []*/,
        ...(isWholesaler
          ? [
              {
                heading: t('Grossista'),
                items: [
                  {
                    name: t('Offerte'),
                    icon: AirlineSeatIndividualSuiteIcon,
                    link: '/deals',
                    badge: location.pathname.startsWith('/deals/edit')
                      ? 'EDIT'
                      : undefined,
                    items: [
                      ...(canListDeals
                        ? [
                            {
                              name: t('Lista'),
                              link: 'deals/list'
                            }
                          ]
                        : []),
                      ...(canCreateDeals
                        ? [
                            {
                              name: t('Crea'),
                              link: 'deals/create'
                              // badgeTooltip: 'Add new deal',
                            }
                          ]
                        : [])
                    ]
                  },
                  {
                    name: t('Distributori'),
                    icon: PeopleAlt,
                    link: '/dealers'
                  }
                ]
              }
            ]
          : []),
        ...(hasSomeDealerRights
          ? [
              {
                heading: t('Distribuzione'),
                items: [
                  ...(canCustomize
                    ? [
                        {
                          name: t('Personalizza'),
                          icon: EditIcon,
                          link: '/customize-building'
                        }
                      ]
                    : []),
                  ...(canBookings
                    ? [
                        {
                          name: t('Prenotazioni'),
                          icon: Luggage,
                          link: '/bookings'
                        }
                      ]
                    : []),
                  ...(isDealer
                    ? [
                        {
                          name: t('Grossisti'),
                          icon: LocationCity,
                          link: '/wholesalers'
                        }
                      ]
                    : []),
                  ...(isDealer
                    ? [
                        {
                          name: t('Codici sconto'),
                          icon: LocalOffer,
                          link: '/discount-code'
                        }
                      ]
                    : []),
                  ...(isDealer
                    ? [
                        {
                          name: t('Strutture in risalto'),
                          icon: FeaturedVideo,
                          link: '/featured'
                        }
                      ]
                    : []),
                  ...(canCustomers
                    ? [
                        {
                          name: t('Clienti'),
                          icon: People,
                          link: '/customers'
                        }
                      ]
                    : [])
                ]
              }
            ]
          : [])
        // ...canGetOwnUser && canUpdateOwnUser ? [{
        // 	heading: t('Amministrazione'),
        // 	items:   [
        // 		{
        // 			name: t('Utente'),
        // 			icon: PersonIcon,
        // 			link: '/management/user',
        // 		},
        // 	],
        // }] : [],
      ];
};

const menuItems: MenuItems[] = [
  {
    heading: 'Supplier',
    items: [
      {
        name: 'Buildings',
        icon: ApartmentIcon,
        link: '/buildings',
        items: [
          {
            name: 'List',
            link: 'buildings/list',
            badgeTooltip: 'Buildings list overview'
          },
          {
            name: 'Create',
            link: 'buildings/create',
            badgeTooltip: 'Add new building'
          }
        ]
      }
    ]
  },
  {
    heading: 'Wholesaler',
    items: [
      {
        name: 'Deals',
        icon: AirlineSeatIndividualSuiteIcon,
        link: '/deals',
        items: [
          {
            name: 'List',
            link: 'deals/list',
            badgeTooltip: 'Deals list overview'
          },
          {
            name: 'Create',
            link: 'deals/create',
            badgeTooltip: 'Add new deal'
          }
        ]
      }
    ]
  },
  {
    heading: 'Dealer',
    items: []
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'User',
        icon: PersonIcon,
        link: '/management/user'
      }
    ]
  }
];
