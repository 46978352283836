import { lazy } from "react";
import Loader from "../components/Loader";

const Promotions = Loader(lazy(() => import('src/content/supplier/Promotions')));

const buildingsRoutes = [
  {
    path: '/',
    element: <Promotions />,
  },
  {
    path: ':buildingId',
    element: <Promotions />,
  },
];

export default buildingsRoutes;
