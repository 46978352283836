import { lazy } from "react";
import Loader from "../components/Loader";

const Wholesalers = Loader(lazy(() => import('src/content/dealer/Wholesalers')));

const wholesalersRoutes = [
  {
    path: '/',
    element: <Wholesalers />,
  },
];

export default wholesalersRoutes;
